import templateService from '../../../../../services/template';

export default {
  name: 'gallery',
  props: {
    templateExists: Boolean
  },
  data() {
    return {
      // htmlTemplate: [],
      // ampTemplate: [],
      visible: false,
      savedGallery: [],
      defaultGallery: [],
      galleryMode: 'default',

      // laoder variables
      fetchingSavedGallery: false,
      deletingTemplate: false,
      fetchingDefaultGallery: false
    };
  },
  methods: {
    async fetchDefaultTemplates() {
      try {
        this.fetchingDefaultGallery = true;

        let result = await templateService.fetchDefaultTemplateList();
        this.defaultGallery = result.data;
        this.fetchingDefaultGallery = false;
      } catch (error) {
        this.errorToast(`Something's wrong. Please contact support.`);
        this.reportError(error);
      }
    },
    fetchSavedGallery() {
      let params = {};
      this.fetchingSavedGallery = true;
      templateService
        .fetchMyGalleryTemplateList(params, this)
        .then((result) => {
          if (result.data.success) {
            this.savedGallery = result.data.data;
            // Filter templates without 'amp' (for 'default' mode or other modes)
            if (this.galleryMode === 'custom2') {
              this.savedGallery = this.savedGallery.filter((template) => template.amp);
            } else if (this.galleryMode === 'custom') {
              this.savedGallery = this.savedGallery.filter((template) => !template.amp && template.html);
            }
          } else {
            console.error('Failed to fetch gallery templates');
          }
          this.fetchingSavedGallery = false;
        })
        .catch((error) => {
          this.fetchingSavedGallery = false;
          this.reportError(error);
        });
    },
    async onApplyTemplate(template) {
      try {
        let content = null;
        // frm my gallery
        // Read contents at given bucket path
        content = JSON.parse(JSON.stringify(template));
        if (typeof content.json == 'string') {
          content.json = JSON.parse(template.json);
        }

        // Extract conten type
        let type = content.type.split(',').length > 1 ? content.type.split(',')[1] : content.type;
        content.type = type;

        // Send content to parent
        this.$emit('templateSelected', content);
      } catch (err) {
        this.reportError('Failed to fetch contens of selected template.', err);
      }
    },

    onChangeGalleryMode(newGalleryMode) {
      this.galleryMode = newGalleryMode;
      if (this.galleryMode === 'custom') {
        this.fetchSavedGallery();
      }
    },
    onChangeGalleryAmpMode(newGalleryMode) {
      this.galleryMode = newGalleryMode;
      // Handle loading state
      this.showAMPTemplate = true;
      this.fetchingSavedGallery = true;
      if (this.galleryMode === 'custom2') {
        this.fetchSavedGallery();
      } else {
        console.log('Other mode selected', newGalleryMode);
      }
    },

    // Delete tempale by ID
    deleteTemplate(templateId) {
      let params = {
        templateId: templateId
      };
      this.deletingTemplate = true;
      templateService
        .deleteTemplateById(params, this)
        .then((result) => {
          if (result.data.success) {
            this.successToast(`Template successfully deleted.`);
            this.fetchSavedGallery();
          }
          this.deletingTemplate = false;
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast(`Something went wrong.`);
          this.deletingTemplate = false;
        });
    }
  },
  mounted() {
    this.fetchDefaultTemplates();
    this.fetchSavedGallery();
  }
};
