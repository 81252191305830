import { fetchChannelProviderList } from '../../../../../services/campaign';
import { fetchUserPropertyList } from '../../../../../services/customer';
import html2canvas from 'html2canvas';
import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';
import TemplateGallery from '../templateGallery/templateGallery';
import ImageManager from '@/components/campaign/contentBuilder/imageUpload/imageUpload';
var Joi = require('joi-browser');
import CkTextEditor from '@/components/ckTextEditor/ckTextEditor';
import DragDropEditor from '@/components/campaign/contentBuilder/dragDropEditor/dragDropEditor';
import { Picker } from 'emoji-mart-vue';
import templateService from '../../../../../services/template';
import UnlayerEmailEditor from '@/components/campaign/contentBuilder/unlayerDragDropEditor/unlayerDragDropEditor.vue';
import campaignPreviewService from '@/services/campaignMessagePreview';
const _ = require('lodash');

export default {
  name: 'emailEditor',
  props: {
    fromName: String,
    subject: String,
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isJourneyCampaign: {
      type: Boolean,
      required: true
    },
    onSaveDraftParent: {
      required: false
    }
  },
  data() {
    let initialEmailList = [];
    if (this.$store.state.token.email) {
      initialEmailList.push(this.$store.state.token.email);
    }

    return {
      contentAlreadyRendered: false,
      // editorType: 'richText',
      editorType: 'dragAndDrop',

      previewMode: true,
      previewDeviceType: 'desktop',
      // amp-editor access
      showAMPHTMLEditor: true,
      showAMPHTMLButton: true,
      isBtnVisible: false,
      // emoji popover variables
      emojiPicker: false,
      // ckeditor dyamicuser filter popover variables
      ckEditorDynmicPopover: false,

      //Email input form
      providerList: null,
      subjectDynmicPopover: false,
      subjectEmojiPicker: false,
      previewTextDynmicPopover: false,
      previewTextEmojiPicker: false,
      emailInputForm: {
        fromName: null,
        subject: null,
        providerId: null,
        previewText: null,
        replyToMail: null
      },
      emailInputFormRules: {
        fromName: [
          { required: true, message: 'From name is required.', trigger: 'blur' },
          { min: 1, max: 50, message: 'From name can not exceed 50 characers.', trigger: 'blur' }
        ],
        subject: [
          { required: true, message: 'Subject is required.', trigger: 'blur' },
          { min: 1, max: 500, message: 'Subject can not exceed 500 characers.', trigger: 'blur' }
        ],
        previewText: [{ min: 0, max: 500, message: 'Preview Text can not exceed 500 characers.', trigger: 'blur' }],
        replyToMail: [{ min: 0, max: 500, message: 'Reply-To id can not exceed 500 characers.', trigger: 'blur' }],
        providerId: [{ required: true, message: 'Provider is required.', trigger: 'blur' }]
      },
      showEmailEditor: false,

      // Used by rich text & custom html
      finalContent: {
        html: '',
        amp: '',
        json: ''
      },

      //Custom html fields
      customHtml: '',
      customAmp: '',
      customHtml2: '',
      amp: '',
      //Attachment variables
      showAttachmentsDialog: false,
      attachments: [],
      attachmentsToUpload: [],
      attachmentsToRemove: [],

      // send test mail
      sendTestMailDialogVisible: false,
      isCommaSeepratedList: false,
      sendingTestEmail: false,
      emails: initialEmailList,
      commaSeperatedEmails: '',
      testEmailForm: {
        emailValue: '',
        subject: '',
        fromName: ''
      },

      // Template gallrey fields
      selectTemplateDialogVisible: false,

      // Save template fields
      savingTemplate: false,
      saveTemplateDialogVisible: false,
      saveTemplateName: ''
    };
  },
  components: {
    TemplateGallery,
    CkTextEditor,
    Picker,
    DynamicUserProperty,
    DragDropEditor,
    ImageManager,
    UnlayerEmailEditor
  },
  methods: {
    onToggleAMPHTMLEditorPreview() {
      if (this.editorType == 'customAmp') {
        this.showAMPHTMLEditor = !this.showAMPHTMLEditor;
        this.isBtnVisible = !this.isBtnVisible;
        this.showAMPHTMLButton = true;
      } else {
        this.showAMPHTMLButton = false;
      }
    },

    addDynamicPropToSubject(text) {
      let position = document.getElementById('inputEmailSubject').selectionStart;
      position = position ? position : 0;

      let subject = this.emailInputForm.subject ? this.emailInputForm.subject : '';
      subject = `${subject.slice(0, position)}${text}${subject.slice(position)}`;

      this.emailInputForm.subject = subject;
      this.subjectDynmicPopover = false;
    },

    addEmojiToSubject(emoji) {
      let position = document.getElementById('inputEmailSubject').selectionStart;
      position = position ? position : 0;

      let subject = this.emailInputForm.subject ? this.emailInputForm.subject : '';
      subject = `${subject.slice(0, position)}${emoji.native}${subject.slice(position)}`;

      this.emailInputForm.subject = subject;
      this.emojiPicker = false;
    },

    addDynamicPropToPreviewText(text) {
      let position = document.getElementById('inputEmailPreviewText').selectionStart;
      position = position ? position : 0;

      let subject = this.emailInputForm.previewText ? this.emailInputForm.previewText : '';
      subject = `${subject.slice(0, position)}${text}${subject.slice(position)}`;

      this.emailInputForm.previewText = subject;
      this.previewTextDynmicPopover = false;
    },

    addEmojiToPreviewText(emoji) {
      let position = document.getElementById('inputEmailPreviewText').selectionStart;
      position = position ? position : 0;

      let subject = this.emailInputForm.previewText ? this.emailInputForm.previewText : '';
      subject = `${subject.slice(0, position)}${emoji.native}${subject.slice(position)}`;

      this.emailInputForm.previewText = subject;
      this.emojiPicker = false;
    },

    closeSendTesEmailDialog() {
      this.sendTestMailDialogVisible = false;
      // this.emails = [];
    },

    removeEmails(email) {
      this.emails.splice(this.emails.indexOf(email), 1);
    },

    handleInputConfirm() {
      let inputValue = this.testEmailForm.emailValue;
      if (inputValue) {
        this.emails.push(inputValue);
      }
      this.testEmailForm.emailValue = '';
    },

    async fetchMergeTagList() {
      let emailMergeTagList = [];
      let result = await fetchUserPropertyList(null, this);
      let props = result.data.data;
      for (let i = 0; i < props.length; i++) {
        emailMergeTagList.push({
          name: props[i].name,
          value: `{{user[${props[i].name}]|default:''}}`
        });
      }
      return emailMergeTagList;
    },

    async fetchMailProviderList() {
      try {
        let params = {
          channel: 'email',
          readArchived: this.readOnly
        };
        let result = await fetchChannelProviderList(params, this);
        if (result.data.success) {
          this.providerList = result.data.data;
          if (result.data.data.length <= 0) {
            this.warningToast('Email provider is not configured.');
          }
          if (this.editorType == 'customAmp') {
            this.showAMPHTMLButton = true;
          } else {
            this.showAMPHTMLButton = false;
          }
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      }
    },

    async validateForm(skipEditorValidation) {
      // Check for pending coupon code.
      if (this.editorType == 'dragAndDrop' && !skipEditorValidation) {
        let result = this.$refs.dragDropEditor.validateContent();
        if (!result) return false;
      } else if (this.editorType == 'richText') {
        // this.$refs.ckeditorRef.renderContent(jsonContent);
      } else if (this.editorType == 'customHtml') {
        // this.customHtml = htmlContent;
      } else if (this.editorType == 'customAmp') {
        // this.customAmp = ampContent;
      }
      // get the provider type
      let providerType = this.providerList.filter((provider) => provider.id === this.emailInputForm.providerId)[0].provider_type;

      // Validate contents.
      if (!skipEditorValidation) {
        // Check For Unsubscribe Link
        let emailContent = await this.getEmailContents();
        console.log('EmailConevt:', emailContent);
        //display only if providertype is not smtp
        if (!['sendgrid', 'amazon_ses', 'smtp', 'growlytics-email'].includes(providerType) && (!emailContent || !emailContent.html || emailContent.html.indexOf('https://dc.growlytics.in/#[unsubscribe]#') || emailContent.amp.indexOf('https://dc.growlytics.in/#[unsubscribe]#') < 0)) {
          this.$swal({
            title: 'Unsubscribe Link Is Missing',
            text: 'Make sure you have added unsubscribe link (https://dc.growlytics.in/#[unsubscribe]#) in email, to keep your email reputation good.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#67C23A',
            confirmButtonText: 'Ok',
            allowEscapeKey: false,
            allowOutsideClick: false
          });
          return false;
        }
      }

      return new Promise((resolve) => {
        this.$refs['emailInputForm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    // This method will be called from parent
    // whenever the campaign content tab is shown [each time, not once]
    async renderCampaignContent(inputContent) {
      this.contentAlreadyRendered = true;

      // Read provider id
      this.fetchMailProviderList();
      this.emailInputForm.providerId = inputContent.providerId;

      // Read subject, fromName campaign config
      if (inputContent.email_config) {
        let emailConfig = typeof inputContent.email_config == 'string' ? JSON.parse(inputContent.email_config) : inputContent.email_config;
        this.emailInputForm.fromName = emailConfig.fromName;
        this.emailInputForm.subject = emailConfig.subject;
        this.emailInputForm.previewText = emailConfig.previewText ? emailConfig.previewText : '';
        this.emailInputForm.replyToMail = emailConfig.replyToMail ? emailConfig.replyToMail : '';
      } else if (inputContent.content && inputContent.content.email_config) {
        let emailConfig = typeof inputContent.content.email_config == 'string' ? JSON.parse(inputContent.content.email_config) : inputContent.content.email_config;
        this.emailInputForm.fromName = emailConfig.fromName;
        this.emailInputForm.subject = emailConfig.subject;
        this.emailInputForm.previewText = emailConfig.previewText ? emailConfig.previewText : '';
        this.emailInputForm.replyToMail = emailConfig.replyToMail ? emailConfig.replyToMail : '';
      }

      // Set Email Content
      let jsonContent = inputContent.content ? inputContent.content.email_json : null;
      let htmlContent = inputContent.content ? inputContent.content.email_html : null;
      let ampContent = inputContent.content ? inputContent.content.email_amp : null;

      if (jsonContent) {
        jsonContent = typeof jsonContent == 'string' ? JSON.parse(jsonContent) : jsonContent;
        this.editorType = jsonContent.type ? jsonContent.type : 'dragAndDrop';
      }

      // Read Attachments
      this.attachments = [];
      // if (inputContent.content && inputContent.content.email_attachments) {
      //   if (inputContent.content.email_attachments == 'email_attachments') this.attachments = JSON.parse(inputContent.content.email_attachments);
      //   else this.attachments = inputContent.content.email_attachments;
      // }

      // If readonly mode, render preview and return.
      if (this.readOnly) {
        this.finalContent = {
          html: htmlContent,
          amp: ampContent,
          json: jsonContent
        };
        return;
      }

      // Render Email Content
      setTimeout(() => {
        if (this.editorType == 'dragAndDrop') {
          this.$refs.dragDropEditor.renderContent(jsonContent);
        } else if (this.editorType == 'unlayerDragDrop') {
          this.$refs.unlayerEmailEditor.renderContent(jsonContent);
          this.showAMPHTMLButton = false;
        } else if (this.editorType == 'richText') {
          this.$refs.ckeditorRef.renderContent(jsonContent);
          this.showAMPHTMLButton = false;
        } else if (this.editorType == 'customHtml') {
          this.customHtml = htmlContent;
          this.showAMPHTMLButton = false;
        } else if (this.editorType == 'customAmp') {
          this.customAmp = ampContent;
          this.customHtml2 = htmlContent;
        } else {
          throw new Error('Invalid editor type:', this.editorType);
        }
        this.finalContent = {
          json: jsonContent,
          html: htmlContent,
          amp: ampContent
        };
      }, 1000);
    },

    renderCampaignContentForJourney(data) {
      if (data.content) {
        let newContent = {};
        newContent.email_json = data.content.json ? data.content.json : data.content.email_json;
        newContent.email_html = data.content.html ? data.content.html : data.content.email_html;
        newContent.email_amp = data.content.amp ? data.content.amp : data.content.email_amp;
        data.content = newContent;
      }
      this.renderCampaignContent(data);
    },

    async readProcessedContentV1() {
      let emailContent = await this.getEmailContents();
      let emailJson = typeof emailContent.json !== 'string' ? JSON.stringify(emailContent.json) : emailContent.json;

      return {
        providerId: this.emailInputForm.providerId,
        email_config: JSON.stringify({
          fromName: this.emailInputForm.fromName,
          subject: this.emailInputForm.subject,
          previewText: this.emailInputForm.previewText ? this.emailInputForm.previewText : '',
          replyToMail: this.emailInputForm.replyToMail ? this.emailInputForm.replyToMail : null
        }),
        content: {
          email_json: emailJson,
          email_html: emailContent.html,
          email_amp: emailContent.amp,
          // email_attachments: JSON.stringify(this.attachments)
          email_attachments: '[]'
        },
        attachmentsToUpload: this.attachmentsToUpload,
        attachmentsToRemove: this.attachmentsToRemove
      };
    },
    onTogglePreview(previewVisible) {
      this.previewMode = previewVisible;
      console.log('PREVIEW MODE:', this.previewMode);
      if (!this.previewMode) {
        requestAnimationFrame(() => {
          if (this.editorType == 'dragAndDrop') this.$refs.dragDropEditor.renderContent(this.finalContent.json);
          else if (this.editorType == 'unlayerDragDrop') this.$refs.unlayerEmailEditor.renderContent(this.finalContent.json);
          else if (this.editorType == 'richText') {
            this.$refs.ckeditorRef.renderContent(this.finalContent.json);
          }
        });
      } else {
        this.getEmailContents(true);
        this.showAMPHTMLButton = false;
      }
      if (this.editorType == 'customAmp') {
        this.showAMPHTMLButton = true;
      }
    },

    async onToggleEditor(showEditor) {
      if (showEditor) {
        // Validate Form First.
        let result = await this.validateForm(true);
        if (!result) {
          this.warningToast('Please provide required input to edit email.');
          return;
        }
        this.onTogglePreview(false);
      } else {
        // Render preview of edited content.
        this.onTogglePreview(true);
      }
      this.showEmailEditor = showEditor;
    },

    async onSaveDraft(exitAfterSave) {
      // Validate Form First.
      let result = await this.validateForm(true);
      if (!result) {
        this.warningToast('Please provide required input to edit email.');
        return;
      }
      this.onSaveDraftParent();

      if (exitAfterSave) {
        this.onToggleEditor(false);
      }
    },
    async onEditorTypeChange(newEditorType, ignoreConfirmation, newEditorData) {
      // // Show warning message.
      // if (!ignoreConfirmation) {
      //   try {
      //     await this.$swal({
      //       title: 'Change Editor?',
      //       text: 'You will loose all the email content you have prepared.',
      //       type: 'warning',
      //       showCancelButton: true,
      //       confirmButtonColor: '#3085d6',
      //       cancelButtonColor: '#d33',
      //       confirmButtonText: 'Change Editor'
      //     });
      //   } catch (err) {
      //     // Ignore cncel click logic
      //     return;
      //   }
      // }

      // Change editor type
      this.editorType = newEditorType;
      this.previewMode = false;
      // Clear all contents
      this.finalContent = {
        html: '',
        amp: '',
        json: ''
      };

      // Render Email Content
      if (this.editorType == 'dragAndDrop') {
        this.$refs.dragDropEditor.renderContent(newEditorData);
      } else if (this.editorType == 'richText') {
        requestAnimationFrame(() => {
          this.$refs.ckeditorRef.renderContent(newEditorData ? newEditorData : '');
        });
      } else if (this.editorType == 'customHtml') {
        this.customHtml = newEditorData;
      } else if (this.editorType == 'customAmp') {
        this.customAmp = newEditorData[0]; // AMP content
        this.customHtml2 = newEditorData[1];
      } else if (this.editorType == 'unlayerDragDrop') {
        requestAnimationFrame(() => {
          this.$refs.unlayerEmailEditor.renderContent(newEditorData ? newEditorData : null);
        });
      } else {
        throw new Error('Invalid editor type:', this.editorType);
      }
    },

    // #region ************************ Start: Rich Text Image Mgt ************************

    async showRichTextImageUploader() {
      this.$refs.richTextImageManager.toggleVisiblity();
    },

    onInsertRichTextImage(imageObject) {
      let imageUrl = imageObject.url;
      this.$refs.ckeditorRef.onInsertImage(imageUrl);
    },

    //#endregion ************************ End: Rich Text Image Mgt ************************

    // #region ************************ Start: Email content prepare ***********************/

    prepareRichTextEditorHtml() {
      let editorContent = this.$refs.ckeditorRef.getProcessedContents();

      // Generate Html
      let html = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <meta http–equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1">      
        <meta name="format-detection" content="telephone=no">
        <meta name="format-detection" content="date=no">
        <meta name="format-detection" content="address=no">
        <meta name="format-detection" content="email=no">
        
        <!-- CSS -->
        <style type="text/css">
          a, a:hover { text-decoration: none; }
          p { margin: 0px !important; white-space:pre-wrap; }
          figure { margin: 0px; }
        </style>
      </head>

      <body style="background-color: white; height: 100% !important; width: 100% !important; 
                        font-weight: 400; font-style: normal; font-size: 12px; font-family: Arial, Helvetica, sans-serif;
                        overflow-wrap: break-word; word-wrap: break-word; -ms-text-size-adjust: 100%;
                        -webkit-text-size-adjust: 100%; margin: 0 auto; padding: 10px 0px 10px 0px;" width="100%">`;

      html += editorContent;

      html += `</body></html>`;

      return {
        html: html,
        json: {
          type: 'richText',
          original: editorContent
        }
      };
    },

    async getEmailContents(forPreview) {
      if (this.editorType == 'dragAndDrop') {
        this.finalContent = this.$refs.dragDropEditor.getProcessedContents(this.emailInputForm.previewText, forPreview);
      } else if (this.editorType == 'richText') {
        this.finalContent = this.prepareRichTextEditorHtml();
      } else if (this.editorType == 'customHtml') {
        this.finalContent = {
          html: this.customHtml,
          json: {
            type: 'customHtml'
          }
        };
      } else if (this.editorType == 'customAmp') {
        this.finalContent = {
          amp: this.customAmp,
          html: this.customHtml2,
          json: {
            type: 'customAmp'
          }
        };
      } else if (this.editorType == 'unlayerDragDrop') {
        this.finalContent = await this.$refs.unlayerEmailEditor.getProcessedContents();
      } else {
        throw new Error('Invalid editor type:', this.editorType);
      }
      console.log('Final contents are', this.finalContent);
      return this.finalContent;
    },

    addDynamicPropToCkEditor(text) {
      this.$refs.ckeditorRef.setDynamicProp(text);
      this.ckEditorDynmicPopover = false;
    },

    addEmojiToCkEditor(emoji) {
      this.$refs.ckeditorRef.setDynamicProp(emoji.native);
      this.emojiPicker = false;
    },

    // #endregion ************************ End: Email content prepare ***********************/

    // #region ************************ Start: Attachments mehtods ***********************/

    removeAttachedFile(index, attachedfile) {
      this.attachments.splice(index, 1);
      _.remove(this.attachmentsToUpload, function (attachment) {
        return attachment.uid == attachedfile.uid;
      });

      // Push into files to remove array
      this.attachmentsToRemove.push(attachedfile);
    },

    onFileAttachmentUpload(file) {
      // sum wrapper for object array
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += this[i][prop];
        }
        return total;
      };

      const maxSize = 1.5e7;
      let reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      let item = this;
      reader.onload = (e) => {
        try {
          // Remove file from remove list
          _.remove(item.attachmentsToRemove, function (attachment) {
            return attachment.uid == file.uid;
          });

          let totalSize = item.attachments.sum('size');
          if (totalSize + file.size > maxSize) {
            e.preventDefault();
            this.errorToast('Total Attachment size can not exceed 15Mb');
            return;
          } else {
            // Add into files to upload
            item.attachments.push({
              uid: file.uid,
              name: file.name,
              size: file.size
            });
            item.attachmentsToUpload.push(file);
          }
        } catch (err) {
          item.reportError(err);
        }
      };
      reader.onerror = function (err) {
        item.reportError(err);
      };
    },

    onAttachmentsToRemove(file) {
      if (this.campaignId) {
        let result = _.find(this.attachments, function (attachment) {
          return attachment.uid == file.ui;
        });
        if (result) this.attachmentsToRemove.push(result);
      }
    },

    // #endregion ************************ End: Attachments mehtods ***********************/

    // #region ************************ Start: Send Test Email mehtods ***********************/

    async showSendTestMailDialog() {
      let result = await this.validateForm();
      if (!result) return;

      this.testEmailForm.fromName = this.emailInputForm.fromName;
      this.testEmailForm.subject = this.emailInputForm.subject;
      this.sendTestMailDialogVisible = true;
    },

    clearTestEmailForm() {
      this.resetTestEmailForm();
      this.sendTestMailDialogVisible = false;
    },

    resetTestEmailForm() {
      // this.emails = [];
      this.testEmailForm.fromName = '';
      this.testEmailForm.subject = '';
    },

    addSendTestEmail() {
      this.testEmailForm.emails.push({
        key: Date.now(),
        value: ''
      });
    },

    removeEmailForm(item) {
      var index = this.testEmailForm.emails.indexOf(item);
      if (index !== -1) {
        this.testEmailForm.emails.splice(index, 1);
      }
    },

    async onSendTestEmailSubmit() {
      this.sendingTestEmail = true;
      let sendTestEmailErrorMsg = null;

      if (this.isCommaSeepratedList) {
        let list = this.commaSeperatedEmails.split(',');
        this.emails = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].trim()) this.emails.push(list[i].trim());
        }
      }

      const memberSchema = Joi.object().keys({
        Emails: Joi.array()
          .items(
            Joi.string()
              .email({
                // minDomainAtoms: 2
              })
              .required()
          )
          .error(() => {
            return {
              message: 'Please Provide valid email list.'
            };
          }),
        'Form Name': Joi.string()
          .required()
          .error(() => {
            return {
              message: 'Please Provide Form name.'
            };
          }),
        'Form Subject': Joi.string()
          .required()
          .error(() => {
            return {
              message: 'Please Provide Email Subject.'
            };
          })
      });

      var validationResult = memberSchema.validate({
        Emails: this.emails,
        'Form Name': this.testEmailForm.fromName,
        'Form Subject': this.testEmailForm.subject
      });

      if (validationResult.error) {
        sendTestEmailErrorMsg = validationResult.error.details[0].message;
        this.errorToast(sendTestEmailErrorMsg);
        this.sendingTestEmail = false;
      } else {
        let contents = await this.getEmailContents(true);
        await this.sendTestEmail(contents.html);
      }
    },

    async sendTestEmail(htmlBody) {
      if (!htmlBody) {
        this.sendingTestEmail = false;
        return this.errorToast(`Empty email content!`);
      }

      let campaignContent = await this.readProcessedContentV1();
      campaignContent.email_config = JSON.parse(campaignContent.email_config);

      try {
        let params = {
          to: this.emails,
          body: campaignContent.content.email_html,
          ampbody: campaignContent.content.email_amp,
          subject: campaignContent.email_config.subject,
          fromName: campaignContent.email_config.fromName,
          replyToMail: campaignContent.email_config.replyToMail,
          providerId: campaignContent.providerId
        };
        let result = await campaignPreviewService.sendEmailCampaignPreview(params, this);
        if (result.data.success) {
          this.successToast(`Test Email sent.`);
          this.clearTestEmailForm();
          this.showPushNotificationTestPopup = false;
        } else {
          let failureMsg = result.data.statusCode + ' - ' + result.data.statusMessage;
          this.errorToast('Failed to send test email - ', failureMsg);
        }
        this.sendingTestEmail = false;
      } catch (err) {
        this.sendingTestEmail = false;
        this.reportError(err);
        this.errorToast('Failed to send test email, please contact support.');
      }
    },

    // #endregion ************************ End: Send Test Email mehtods ***********************/

    // #region ************************ Start: Template Gallery mehtods ***********************/

    // show select template dialog,
    onShowTemplateSelectorDialog() {
      this.$refs.templateGallery.visible = true;
      // Simplify logic since both gallery modes call the same function
      setTimeout(() => {
        if (['custom', 'custom2'].includes(this.$refs.templateGallery.galleryMode)) {
          this.$refs.templateGallery.fetchSavedGallery();
        }
      }, 100);
    },

    async onApplyTemplate(template) {
      this.$refs.templateGallery.visible = false;

      // Read editor type from new template
      let incomingEditorType = template.type ? template.type : 'dragAndDrop';

      // Prepare content for editor type.
      let content = null;
      let ampContent = [];
      if (incomingEditorType == 'richText') {
        content = template.html;
      } else if (incomingEditorType == 'customHtml') {
        content = template.html;
      } else if (incomingEditorType == 'customAmp') {
        ampContent = [template.amp, template.html];
        content = ampContent;
      } else if (incomingEditorType == 'dragAndDrop') {
        content = template.json;
      } else if (incomingEditorType == 'unlayerDragDrop') {
        content = template.json;
      }
      // change editor type according to incoming template
      await this.onEditorTypeChange(incomingEditorType, true, content);
    },

    // #endregion ************************ End: Template Gallery mehtods ***********************/

    // #region ************************ Start: Template Gallery mehtods ***********************/

    showSaveTemplateDialog() {
      this.saveTemplateName = '';
      this.saveTemplateDialogVisible = true;
      this.saveTemplateAmpDialogVisible = true;
    },

    async onSumbitTemplateName() {
      if (!this.saveTemplateName) {
        this.errorToast('Template name can not be empty!');
        return;
      }

      this.savingTemplate = true;
      try {
        let content = await this.getEmailContents();
        let jsonFile = content.json;
        let htmlFile = content.html;
        let ampFile = content.amp;

        // First draw tempalte in a inframe
        this.$refs.dummyFrame.setAttribute('srcdoc', htmlFile);
        this.$refs.dummyFrame.setAttribute('srcdoc', ampFile);
        // Set params for saving template
        const params = {
          templateType: 'email,' + this.editorType,
          jsonTemplate: typeof jsonFile == 'string' ? jsonFile : JSON.stringify(jsonFile),
          htmlTemaplate: htmlFile,
          templateId: '',
          templateName: this.saveTemplateName
        };
        // Check if ampFile Template To Set Amp Template
        if (ampFile) {
          params.ampTemplate = ampFile;
        }
        let result = await templateService.saveTemplate(params);
        console.log('Template saved:', result);

        if (result.data.success) {
          this.templateId = result.data.data.templateId;

          // save image of template in background
          let node = this.$refs.dummyFrame.contentDocument.body;
          let item = this;
          requestAnimationFrame(() => {
            html2canvas(node)
              .then((canvas) => {
                let imagedata = canvas.toDataURL('image/png');
                // this.$refs.imgTag.style.backgroundImage = 'url(' + imagedata + ')';

                // update image of the template
                templateService
                  .updateTemplateImage(
                    {
                      templateId: item.templateId,
                      image: imagedata
                    },
                    this
                  )
                  .catch((error) => {
                    this.reportError('Failed to save template image!');
                    this.reportError(error);
                  });
              })
              .catch((e) => {
                this.reportError('Failed to convert html to canvas');
                this.reportError(e);
                console.log('Failed to convert html to canvas', e);
              });
          });
        } else {
          throw new Error('failed to save template.', result.data);
        }

        this.successToast('Template saved.');
        this.saveTemplateDialogVisible = false;
      } catch (error) {
        this.errorToast('Something went wrong. Failed to save template.');
        this.reportError(error);
      } finally {
        this.savingTemplate = false;
      }
    }
  },
  // #endregion ************************ End: Template Gallery mehtods ***********************
  mounted() {
    if (this.$route.query.templateId) {
      this.templateId = this.$route.query.templateId;
    }
    // Report event.
    this.marketingTrackEvent('Email Editor Opened', {
      Channel: 'Email',
      'Company Id': this.$store.state.token.companyId,
      'User Name': this.$store.state.token.name,
      'User Email': this.$store.state.token.email,
      'Platform Type': this.$store.state.token.companyPlatformType
    });
  },

  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    // this.editor.destroy()
  }
};
